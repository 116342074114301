import { useCallback, useEffect, useState } from "react";
import API_CONFIG from "../config";
import axios from "axios";

const cache = {};

export const useFetch = (endpoint) => {
  const cacheKey = endpoint.substr(1);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [is404, setIs404] = useState(false);
  const fn = useCallback(
    async function () {
      try {
        // setLoading(true);
        const response = await axios.get(
          `${API_CONFIG.baseURL}/api${endpoint}?populate=deep,5`
        );
        if (endpoint === "/posts" || endpoint === "/sub-categories") {
          // adding data to cache
          cache[cacheKey] = response.data.data;

          setData(response.data.data);
        } else {
          setData(
            Array.isArray(response.data.data)
              ? response.data.data
              : response.data.data.attributes
          );

          // adding data to cache
          cache[cacheKey] = Array.isArray(response.data.data)
            ? response.data.data
            : response.data.data.attributes;
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setIs404(true);
        } else {
          console.log(error);
        }
      } finally {
        setLoading(false);
      }
    },
    [endpoint]
  );

  useEffect(() => {
    if (!cache[cacheKey]) {
      fn();
    }
  }, []);

  // returning cache data if available
  return {
    loading: cache[cacheKey] ? false : loading,
    data: cache[cacheKey] || data,
    is404,
  };
};
